import { useField } from "formik";
import { useState } from "react";

const FormTextInput = ({
  label,
  price,
  type,
  containerClassName,
  disabled,
  max,
  noValue,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [inputValue, setInputValue] = useState(field.value || "");

  const handleChange = (e) => {
    const value = String(e.target.value).replaceAll(",", "");
    if (type === "number") {
      if (/^(\d*\.?\d*)$/.test(value)) {
        if (!value && value !== 0) {
          setInputValue("");
          helpers.setValue("");
        } else {
          setInputValue(price ? Number(value).toLocaleString("en-US") : value);
          helpers.setValue(value);
        }
      }
    } else {
      if(max && value.length > max) return;
      setInputValue(value);
      helpers.setValue(value);
    }
  };

  return (
    <div
      className={`${containerClassName || ""}`}
    >
      <label htmlFor={props.id || props.name}>{label}</label>
      <input
        className="text-input"
        {...field}
        {...props}
        type={type == "number" ? "text" : type}
        value={noValue ? "" : inputValue}
        onChange={handleChange}
        max={max}
        disabled={disabled}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};



export { FormTextInput};

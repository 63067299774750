import React from "react";

import BannerImage from "../assets/images/talk-to-us.jpg";
import "../styles/_banner.scss";

const Banner = () => {
  return (
    <div
      className="banner"
      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
    >
      <div className="overlay"></div>
      <div className="banner-content">
        <h1 className="banner-title">Talk to Us</h1>
      </div>
    </div>
  );
};

export default Banner;

import React from 'react';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import FormSection from '../components/FormSection';
import Footer from '../components/Footer';
import '../styles/App.scss';

const App = () => {
  return (
    <div>
      <Navbar />
      <Banner />
      <FormSection />
      <Footer />
    </div>
  );
};

export default App;

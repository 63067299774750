import React from "react"
import "../styles/_footer.scss"

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer__section footer__sales">
				<h4>Visit Us:</h4>
				<p>Community Center, Whyt, RV4</p>
			</div>
			<div className="footer__section footer__email">
				<h4>Email</h4>
				<p>
					<a href="mailto:care@owest.com.eg">care@owest.com.eg</a>
				</p>
			</div>
			<div className="footer__section footer__call">
				<h4>Call Center</h4>
				<p>16595</p>
			</div>
		</footer>
	)
}

export default Footer

import React, { useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { PhoneInput } from "react-phonenr-input"

import { FormTextInput } from "../components/FormTextInput"
import UploadIcon from "../assets/images/upload.png"
import "../styles/_form.scss"

const FormSection = () => {
	const [thankYou, setThankYou] = useState(false)
	const [isloading, setIsloading] = useState(false)

	const handleFormSubmit = async (values) => {
		const formData = new FormData()
		formData.append("first_name", values.name)
		formData.append("mobile", values.phoneNumber.replace(/\s/g, ""))
		formData.append("email", values.email)
		formData.append("looking_for", values.message)
		formData.append("id_image", values.file)
		formData.append("type", "talk_us")

		try {
			setIsloading(true)

			const response = await axios.post(
				"https://api.owest.autoconnect.digital/api/inquiry",
				formData
			)

			if (response.status === 200) {
				setThankYou(true)
				setIsloading(false)
			}
		} catch (error) {
			console.log("FAILED...", error)
		} finally {
			setIsloading(false)
		}
	}
	return (
		<section className="form-section">
			<div className="inner-container">
				<div className="text-details-container">
					<h1>YOUR VOICE, OUR COMMUNITY'S HEARTBEAT.</h1>
					<p>
						At O West, your thoughts make a difference! Whether you’ve got a
						suggestion, a concern, or something we can improve, we want to hear
						it all. Your feedback helps us build a community we all love. 
            <br />
            <br />
             Dropus a message or send an image. Together, we're building something
						special at O West!
					</p>
				</div>
				<div className="form-container">
					{thankYou ? (
						<div className="thankyou-container">
							<h3 className="talk-to-us">
								Thank you for contacting us!
								<br /> We have received your request and will respond as soon as
								possible to assist you.
							</h3>
						</div>
					) : isloading ? (
						<>
							<div className="loading zone-form">
								<div className="lds-ring">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
								<div className="uploading-text-container">
									<p>Please wait, uploading your image...</p>
								</div>
							</div>
						</>
					) : (
						<Formik
							initialValues={{
								name: "",
								email: "",
								phoneNumber: "",
								message: "",
								file: null,
							}}
							validationSchema={Yup.object({
								name: Yup.string().required("Required"),
								phoneNumber: Yup.string()
									.min(10, "Your Phone number must be 10 Numbers or more.")
									.required("Required"),
								email: Yup.string()
									.email("Please Enter a valid Email")
									.required("Required"),
								message: Yup.string()
									.max(500, "Your Message must be 500 Characters or less.")
									.required("Required"),
								file: Yup.mixed()
									.required("An image is required")
									.test("fileType", "Unsupported file format", (value) => {
										return (
											value && ["image/jpeg", "image/png"].includes(value.type)
										)
									})
									.test("fileSize", "File is too large", (value) => {
										return value && value.size <= 2000000 // 2MB limit
									}),
							})}
							onSubmit={async (values, { setSubmitting }) => {
								await handleFormSubmit(values)
								setSubmitting(false)
							}}
						>
							{(formik) => (
								<form
									className="contact-form-container"
									onSubmit={formik.handleSubmit}
								>
									<div className="form-inputs-container">
										<div className="input-container">
											<FormTextInput
												label=""
												name="name"
												type="text"
												placeholder="Name"
												className="text-input"
											/>
										</div>
										<div className="input-container phone-container">
											<PhoneInput
												value={formik.values.phoneNumber}
												onChange={(e) => formik.setFieldValue("phoneNumber", e)}
												onBlur={formik.handleBlur("phoneNumber")}
												name="phoneNumber"
												label=""
												defaultCountry="EG"
											/>
											{formik.touched.phoneNumber &&
											formik.errors.phoneNumber ? (
												<div className="error">{formik.errors.phoneNumber}</div>
											) : null}
										</div>
										<div className="input-container">
											<FormTextInput
												label=""
												name="email"
												type="text"
												placeholder="Email"
												className="text-input"
											/>
										</div>
										<div className="input-container">
											<FormTextInput
												label=""
												name="message"
												type="text"
												placeholder="message"
												className="text-input"
											/>
										</div>
										<div className="input-container file-upload-container">
											<input
												type="file"
												id="file-upload"
												name="file"
												onChange={(event) => {
													formik.setFieldValue(
														"file",
														event.currentTarget.files[0]
													)
												}}
												className="file-input"
												accept="image/jpeg,image/png"
											/>
											<label
												htmlFor="file-upload"
												className="custom-file-label"
											>
												<span className="file-upload-placeholder">
													{formik.values.file
														? formik.values.file.name
														: "Upload Image"}
												</span>
												<img
													className="upload-img"
													src={UploadIcon}
													alt="upload"
												/>{" "}
											</label>
											{formik.touched.file && formik.errors.file ? (
												<div className="error">{formik.errors.file}</div>
											) : null}
										</div>
										<div className="form-button-container flip-container">
											<button
												type="submit"
												className="form-submit-btn sharp-sans white"
											>
												Send
											</button>
										</div>
									</div>
								</form>
							)}
						</Formik>
					)}
				</div>
			</div>
		</section>
	)
}

export default FormSection
